//----------------------
// Mobile size - Tranferencias Programadas
var mobileSizeTP = 632;
//----------------------
// Resize Inputs - Style
function dynamicInputs(element) {
  if ($(window).width() > mobileSizeTP) {
    var newWidth = element.children('option:selected').text().length * 8;
    var cotaInferior = element.css('min-width').replace('px', '');
    var cotaSuperior = element.parents('.tab-content').width() - 220;
    if (newWidth > cotaInferior) {
      if (newWidth < cotaSuperior) {
        element.css('width', newWidth + 'px');
      } else {
        element.css('width', cotaSuperior + 'px');
      }
    } else {
      element.css('width', cotaInferior + 'px');
    }
  }
}
//----------------------
// Resize Inputs - Style
function resizeDynamicInputs() {
  var cpOrigen = $('#cp-cuenta-origen-field');
  var ctOrigen = $('#ct-cuenta-origen-field');
  var destino = $('#cp-cuenta-destino-field');
  if ($(window).width() <= mobileSizeTP) {
    cpOrigen.css('width', '100%');
    ctOrigen.css('width', '100%');
    destino.css('width', '100%');
  } else {
    if (cpOrigen.val()) {
      dynamicInputs(cpOrigen);
    } else {
      cpOrigen.css('width', cpOrigen.css('min-width'));
    }
    if (ctOrigen.val()) {
      dynamicInputs(ctOrigen);
    } else {
      ctOrigen.css('width', ctOrigen.css('min-width'));
    }
    if (destino.val()) {
      dynamicInputs(destino);
    } else {
      destino.css('width', destino.css('min-width'));
    }
  }
}
//----------------------
// DOCUMENT READY
$(document).ready(function() {
  resizeDynamicInputs();
});
//----------------------
// Change Tab - Events:OnClick
$('#nav-cuenta-propia-tab').on('click', function() {
  resizeDynamicInputs();
});
$('#nav-cuenta-terceros-tab').on('click', function() {
  resizeDynamicInputs();
});
$('#transferencias-confirmacion .continue-button').on('click', function() {
  resizeDynamicInputs();
});
//----------------------
// Resize Inputs - Events
$('#cp-cuenta-origen-field').on('input change keyup', function() {
  dynamicInputs($(this));
});
$('#ct-cuenta-origen-field').on('input change keyup', function() {
  dynamicInputs($(this));
});
$('#cp-cuenta-destino-field').on('input change keyup', function() {
  dynamicInputs($(this));
});

//----------------------
//Resize Button - Style && Resize transferenciasProgramadas.html - Events
$(window).resize(function() {
  resizeDynamicInputs();
});
//----------------------
$ = window.$;
