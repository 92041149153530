let $ = require('jquery');
window.$ = window.jQuery = $;
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-es';
datepickerFactory($);
datepickerJAFactory($);
//----------------------
// Mobile size - Tranferencias Programadas
var mobileSizeTP = 632;
// Calendar style
var isJqueryUI = null;
function getTodayFormattedInverted() {
  // Retorno: ISO Date	"YYYY-mm-dd" (The International Standard) - Formato: String
  var today = new Date();
  var todayFormatted =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2);
  return todayFormatted;
}
//----------------------
// Get Date
function formatStringDate(value) {
  // Retorno: "dd/mm/YYYY" (Visual) - Formato: String
  // Entrada: ISO Date "YYYY-mm-dd" (The International Standard) - Formato: String
  var parts = value.split('-');
  var toDate = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
  var dateFormatted =
    ('0' + toDate.getDate()).slice(-2) +
    '/' +
    ('0' + (toDate.getMonth() + 1)).slice(-2) +
    '/' +
    toDate.getFullYear();
  return dateFormatted;
}
function formatStringDateInverted(value) {
  // Retorno: ISO Date	"YYYY-mm-dd" (The International Standard) - Formato: String
  // Entrada: "dd/mm/YYYY"  (Visual) - Formato: String
  var parts = value.split('/');
  var toDate = new Date(parts[2], parts[1] - 1, parts[0], 0, 0, 0);
  var dateFormatted =
    toDate.getFullYear() +
    '-' +
    ('0' + (toDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + toDate.getDate()).slice(-2);
  return dateFormatted;
}
//----------------------
// Toggle Calendar
function toggleCalendarInput() {
  var campoFecha = $('#ct-fecha-transaccion-field');
  var campoFechaValue = $('#ct-fecha-transaccion-field').val();
  if ($(window).width() <= mobileSizeTP) {
    campoFecha.datepicker('destroy');
    campoFecha.removeClass('hasDatepicker');
    if (campoFechaValue.length == 0 || campoFechaValue.toLowerCase() == 'hoy') {
      campoFecha.val(getTodayFormattedInverted());
    } else {
      campoFecha.val(formatStringDateInverted(campoFechaValue));
    }
    campoFecha.prop('type', 'date');
    isJqueryUI = false;
  } else {
    isJqueryUI = true;
    campoFecha.prop('type', 'text');
    if (campoFechaValue.length == 0 || campoFechaValue == getTodayFormattedInverted()) {
      campoFecha.val('Hoy');
    } else {
      campoFecha.val(formatStringDate(campoFechaValue));
    }
    campoFecha
      .datepicker({
        minDate: 0,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        dayNamesMin: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        monthNames: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        monthNamesShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        autoSize: true,
        beforeShowDay: $.datepicker.noWeekends,
        dateFormat: 'dd/mm/yy',
        altForma: 'dd/mm/yy',
      })
      .datepicker({ setDate: 'today' });
    $.datepicker.regional['es'];
  }
}
//----------------------
// Set calendar input
function initiateCalendarInput() {
  var campoFecha = $('#ct-fecha-transaccion-field');
  if (isJqueryUI) {
    campoFecha.val('Hoy');
  } else {
    campoFecha.val(getTodayFormattedInverted());
  }
}

//----------------------
// DOCUMENT READY
$(document).ready(function() {
  toggleCalendarInput();
});
//----------------------
// Change Tab - Events:OnClick
$('#nav-cuenta-terceros-tab').on('click', function() {
  initiateCalendarInput();
});
$('#transferencias-comprobante .return-button').on('click', function() {
  initiateCalendarInput();
});
//----------------------
// Resize transferenciasProgramadas.html - Events
$(window).resize(function() {
  var hasToToggle = false;
  if ($(window).width() <= mobileSizeTP) {
    if (isJqueryUI) {
      hasToToggle = true;
    }
  } else {
    if (!isJqueryUI) {
      hasToToggle = true;
    }
  }
  if (hasToToggle) {
    toggleCalendarInput();
  }
});

export { isJqueryUI };

$ = window.$;
