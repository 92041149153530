let $ = require('jquery');
window.$ = window.jQuery = $;
// External tools
require('imask');
require('ie-class');
require('bootstrap');
require('jquery-ui');
var numeral = require('numeral');
// Locals tools
import validateFields from './validations';
import { isJqueryUI } from './datepicker';
require('./dynamicInputs');
require('./jquery.inputmask.bundle');
// Data
import contactos from '../data/contactos.json';
import transferenciaExitosa from '../data/transferenciaExitosa.json';
import { isWeekend as isWeekendFNS } from 'date-fns';
// Global Param
var isIE = false;
var ctCurrencyMask = null;
var cpCurrencyMask = null;
var formToSend = null;
//----------------------
// LOAD CONTENT
//----------------------
function loadDropDownList(ddl, data, defaultValue) {
  document.getElementById(ddl).innerHTML = '';
  var defaultOption = document.createElement('option');
  defaultOption.innerHTML = defaultValue;
  defaultOption.value = '';
  document.getElementById(ddl).appendChild(defaultOption);
  $.each(data, function() {
    var option = document.createElement('option');
    option.innerHTML = this.value;
    option.value = this.id;
    document.getElementById(ddl).appendChild(option);
  });
}

function loadPaisesDropDownList(ddl, data, defaultValue) {
  document.getElementById(ddl).innerHTML = '';
  $.each(data, function() {
    var option = document.createElement('option');
    option.innerHTML = this.value;
    option.value = this.id;
    if (this.id == 'UY') {
      option.selected = 'selected';
    }
    document.getElementById(ddl).appendChild(option);
  });
}

function loadCurrencyExchangeTable() {
  document.getElementById('exchange-table-body').innerHTML = '';
  // Dólar Pizarra
  var tr = document.createElement('tr');
  var td = document.createElement('td');
  td.innerHTML = 'Pizarra';
  tr.appendChild(td);
  td = document.createElement('td');
  td.innerHTML = window.exchangeTable[0].data.compra;
  tr.appendChild(td);
  td = document.createElement('td');
  td.innerHTML = window.exchangeTable[0].data.venta;
  tr.appendChild(td);
  document.getElementById('exchange-table-body').appendChild(tr);
  // Dólar Internet
  var tr = document.createElement('tr');
  var td = document.createElement('td');
  td.innerHTML = 'Internet';
  tr.appendChild(td);
  td = document.createElement('td');
  td.innerHTML = window.exchangeTable[1].data.compra;
  tr.appendChild(td);
  td = document.createElement('td');
  td.innerHTML = window.exchangeTable[1].data.venta;
  tr.appendChild(td);
  document.getElementById('exchange-table-body').appendChild(tr);
  // UI
  var tr = document.createElement('tr');
  var td = document.createElement('td');
  td.colSpan = '3';
  td.innerHTML = 'Valor actual UI: ' + window.exchangeTable[2].value;
  tr.appendChild(td);
  document.getElementById('exchange-table-body').appendChild(tr);
}

function loadContactos() {
  document.getElementById('form-contactos').innerHTML = '';
  $.each(contactos, function() {
    var id = this.id;
    var data = this.data;
    //---------------
    // Container
    var container = document.createElement('div');
    container.className = 'tp-row';
    // Wrapper
    var wrapper = document.createElement('div');
    // Radio
    var radio = document.createElement('input');
    radio.type = 'radio';
    radio.className = 'radiobutton styled-radio';
    radio.id = 'radio-button-' + id;
    radio.name = 'contact';
    wrapper.appendChild(radio);
    // Label
    var label = document.createElement('label');
    var att = document.createAttribute('for');
    att.value = 'radio-button-' + id;
    label.setAttributeNode(att);
    wrapper.appendChild(label);
    container.appendChild(wrapper);
    //---------------
    // Button
    //---------------
    var button = document.createElement('button');
    button.type = 'button';
    container.appendChild(button);
    //---------------
    // div && h4
    //---------------
    // beneficiario
    var div = document.createElement('div');
    div.innerHTML = data.beneficiario;
    container.appendChild(div);
    var name = data.beneficiario;
    // cuentaorigen
    var h4 = document.createElement('h4');
    h4.innerHTML = 'Cuenta:';
    container.appendChild(h4);
    var div = document.createElement('div');
    div.innerHTML = data.nrocuenta;
    container.appendChild(div);
    var account = data.nrocuenta;
    // moneda
    var h4 = document.createElement('h4');
    h4.innerHTML = 'Moneda:';
    container.appendChild(h4);
    var div = document.createElement('div');
    div.innerHTML = data.moneda;
    container.appendChild(div);
    var currency = data.moneda;
    //---------------
    // data
    //---------------
    var dataValue = document.createAttribute('data-value');
    dataValue.value = account;
    radio.setAttributeNode(dataValue);
    var dataText = document.createAttribute('data-text');
    dataText.value = name;
    radio.setAttributeNode(dataText);
    var dataCurrency = document.createAttribute('data-currency');
    dataCurrency.value = currency;
    radio.setAttributeNode(dataCurrency);
    document.getElementById('form-contactos').appendChild(container);
  });
  $('#contactModal input[type=radio]').on('click', function() {
    if ($('#contactModal input[type=radio]:checked').length > 0) {
      $('.btn-seleccionar').prop('disabled', false);
    } else {
      $('.btn-seleccionar').prop('disabled', true);
    }
  });
  $('#contactModal .tp-content button').on('click', function() {
    $(this)
      .siblings('div')
      .children('input[type="radio"]')
      .trigger('click');
  });
}

function successTransference($element) {
  $element.empty();
  $.each(transferenciaExitosa, function() {
    var id = this.id;
    var data = this.data;
    var titles = {
      cuentaorigen: 'Cuenta de origen',
      cuentadestino: 'Cuenta de destino',
      beneficiario: 'Titular de la cuenta de destino',
      destino: 'Destino',
      referencia: 'Referencia',
      fecha: 'Fecha de la transferencia',
      importe: 'Importe a debitar',
    };
    $.each(data, function(index, value) {
      var container = document.createElement('div');
      var title = document.createElement('div');
      var element = document.createElement('div');
      if (index == 'fecha') {
        title.innerHTML = titles[index];
        if (data.fecha == getTodayFormatted()) {
          element.innerHTML = 'Hoy';
        } else {
          element.innerHTML = data.fecha;
        }
        container.appendChild(title);
        container.appendChild(element);
        $element.append(container);
      } else if (index == 'importe') {
        title.innerHTML = titles[index];
        if (data.moneda == 'pesos') {
          element.innerHTML = '$ ' + data.importe;
        } else if (data.moneda == 'dolares') {
          element.innerHTML = 'U$S ' + data.importe;
        } else {
          element.innerHTML = data.importe;
        }
        container.appendChild(title);
        container.appendChild(element);
        $element.append(container);
      } else if (index == 'moneda') {
        // Excluded
      } else {
        title.innerHTML = titles[index];
        element.innerHTML = value;
        container.appendChild(title);
        container.appendChild(element);
        $element.append(container);
      }
    });
  });
}
//----------------------
// DOCUEMNT READY
//----------------------
function removeMaskIE(value) {
  var valor = value;
  var regx = /[^A-Za-z0-9]/g;
  if (regx.test(valor)) {
    if (valor.indexOf(',') != -1) {
      valor = valor.substr(0, value.indexOf(','));
    }
    valor = valor
      .replace('$', '')
      .replace('U', '')
      .replace('S', '')
      .replace('.', '')
      .trim();
  }
  return valor;
}
function addDefaultMaskIE($importe) {
  var valor = removeMaskIE($importe.val());
  var number = numeral(valor);
  var formattedValue = number.format();
  if (valor) {
    $importe.val(formattedValue);
  } else {
    $importe.val('');
  }
}

function addCurrencyMaskIE($importe, currency) {
  if (currency == 'dolares') {
    numeral.locale('in');
  } else {
    numeral.locale('es');
  }
  var valor = removeMaskIE($importe.val());
  var number = numeral(valor);
  var formattedValue = number.format('$ 0,0.00');
  if (valor) {
    $importe.val(formattedValue);
  } else {
    $importe.val('');
  }
}

var advertisement = null;
//----------------------
// Document ready
$(document).ready(function() {
  window.scrollTo(0, 0);
  advertisement = $('#datos-del-inquilino');
  advertisement.remove();
  loadContactos();
  // Cuenta Propia
  loadDropDownList('cp-cuenta-origen-field', window.cuentaOrigen, 'Seleccioná una cuenta');
  loadDropDownList('cp-cuenta-destino-field', window.cuentaDestino, 'Seleccioná una cuenta');
  loadDropDownList(
    'cp-moneda-transaccion-field',
    window.monedaTransaccion,
    'Seleccioná una moneda',
  );
  // Cuenta(s) de terceros en el Banco
  loadDropDownList('ct-cuenta-origen-field', window.cuentaOrigen, 'Seleccioná una cuenta');
  loadDropDownList(
    'ct-moneda-transaccion-field',
    window.monedaTransaccion,
    'Seleccioná una moneda',
  );
  loadDropDownList('ct-destino-field', window.destino, 'Seleccioná un destino');
  loadCurrencyExchangeTable();

  console.log('Document Ready');
  var bodyClass = document.getElementsByTagName('BODY')[0].className;
  var ieNumber = null;
  if (bodyClass.indexOf('ie') > -1) {
    ieNumber = Number(
      document
        .getElementsByTagName('BODY')[0]
        .className.substring(
          document.getElementsByTagName('BODY')[0].className.indexOf('ie') + 2,
          document.getElementsByTagName('BODY')[0].className.indexOf('ie') + 4,
        )
        .trim(),
    );
  }
  if (ieNumber) {
    isIE = true;
    // Mask for IE
    numeral.register('locale', 'es', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function(number) {
        return number === 1 ? 'er' : 'ero';
      },
      currency: {
        symbol: '$',
      },
    });
    numeral.register('locale', 'in', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function(number) {
        return number === 1 ? 'st' : 'st';
      },
      currency: {
        symbol: 'U$S',
      },
    });
    numeral.defaultFormat('0,0.00');
  } else {
    // Mask for all Browsers
    ctCurrencyMask = IMask(document.getElementById('ct-importe-field'), {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 2, // digits after point, 0 for integers
          signed: false, // disallow negative
          thousandsSeparator: '.', // any single char
          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
          normalizeZeros: true, // appends or removes zeros at ends
          radix: ',', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
        },
      },
    });
    cpCurrencyMask = IMask(document.getElementById('cp-importe-field'), {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 2, // digits after point, 0 for integers
          signed: false, // disallow negative
          thousandsSeparator: '.', // any single char
          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
          normalizeZeros: true, // appends or removes zeros at ends
          radix: ',', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
        },
      },
    });
  }
  if (isIE) {
    addCurrencyMaskIE($('#ct-importe-field'), 'pesos');
  }
});

//----------------------
// GENERALS
//----------------------
$('#ct-importe-field').focusout(function() {
  if (isIE) {
    if ($(this).val() == 'pesos') {
      addCurrencyMaskIE($('#ct-importe-field'), 'pesos');
    } else if ($(this).val() == 'dolares') {
      addCurrencyMaskIE($('#ct-importe-field'), 'dolares');
    } else {
      addDefaultMaskIE($('#ct-importe-field'));
    }
  }
});

$('#cp-importe-field').focusout(function() {
  if (isIE) {
    if ($('#cp-moneda-transaccion-field').val() == 'pesos') {
      addCurrencyMaskIE($('#cp-importe-field'), 'pesos');
    } else if ($('#cp-moneda-transaccion-field').val() == 'dolares') {
      addCurrencyMaskIE($('#cp-importe-field'), 'dolares');
    } else {
      addDefaultMaskIE($('#cp-importe-field'));
    }
  }
});

$('#ct-moneda-transaccion-field').on('change', function() {
  var currency = '';
  if ($(this).val() == 'pesos') {
    currency = '$';
    $('#ct-importe-field').attr('placeholder', 'Importe en pesos');
    if (isIE) {
      addCurrencyMaskIE($('#ct-importe-field'), 'pesos');
    }
  } else if ($(this).val() == 'dolares') {
    currency = 'U$S';
    $('#ct-importe-field').attr('placeholder', 'Importe en dólares');
    if (isIE) {
      addCurrencyMaskIE($('#ct-importe-field'), 'dolares');
    }
  } else {
    $('#ct-importe-field').attr('placeholder', 'Importe');
    if (isIE) {
      addDefaultMaskIE($('#ct-importe-field'));
    }
  }
  if (!isIE) {
    ctCurrencyMask.updateOptions({
      mask: currency + ' num',
    });
  }
});

$('#cp-moneda-transaccion-field').on('change', function() {
  var currency = '';
  if ($(this).val() == 'pesos') {
    currency = '$';
    $('#cp-importe-field').attr('placeholder', 'Importe en pesos');
    if (isIE) {
      addCurrencyMaskIE($('#cp-importe-field'), 'pesos');
    }
  } else if ($(this).val() == 'dolares') {
    currency = 'U$S';
    $('#cp-importe-field').attr('placeholder', 'Importe en dólares');
    if (isIE) {
      addCurrencyMaskIE($('#cp-importe-field'), 'dolares');
    }
  } else {
    $('#cp-importe-field').attr('placeholder', 'Importe');
    if (isIE) {
      addDefaultMaskIE($('#cp-importe-field'));
    }
  }
  if (!isIE) {
    cpCurrencyMask.updateOptions({
      mask: currency + ' num',
    });
  }
});

$('#ct-cuenta-destino-field').keypress(function(event) {
  if (event.which != 8 && isNaN(String.fromCharCode(event.which))) {
    event.preventDefault(); //stop character from entering input
  }
});

function removeError($element) {
  $element.siblings('.validation-error-message').css('display', 'none');
  $element.css('border-color', '#cccccc');
}
function addError($element, message) {
  $element.after("<span class='validation-error-message'>" + message + '</span>');
}
function resetForm($form) {
  $form[0].reset();
  $form.find('.input-field').css('border-color', '#cccccc');
  $form.find('.validation-error-message').remove();
}
function hideNote() {
  $('.tp-nota').hide();
  $('.tab-content').css('padding-bottom', '48');
}
function showNote() {
  $('.tp-nota').show();
  $('.tab-content').css('padding-bottom', '32');
}
function showCuentaPropiaButton() {
  $('#ct-submit-button').css('display', 'none');
  $('#cp-submit-button').css('display', 'block');
}
function showCuentaTercerosButton() {
  $('#ct-submit-button').css('display', 'block');
  $('#cp-submit-button').css('display', 'none');
}
//----------------------
// Get today
function getToday() {
  // Retorno: ISO Date	"YYYY-mm-dd" (The International Standard) - Formato: Date
  var today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
}
//----------------------
// Get today formated
function getTodayFormatted() {
  // Retorno: "dd/mm/YYYY" (Visual) - Formato: String
  var today = new Date();
  var todayFormatted =
    ('0' + today.getDate()).slice(-2) +
    '/' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '/' +
    today.getFullYear();
  return todayFormatted;
}
function getTodayFormattedInverted() {
  // Retorno: ISO Date	"YYYY-mm-dd" (The International Standard) - Formato: String
  var today = new Date();
  var todayFormatted =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2);
  return todayFormatted;
}
function formatDate(value) {
  // Retorno: ISO Date	"YYYY-mm-dd" (The International Standard) - Formato: Date
  // Entrada: "dd/mm/YYYY" (Visual) - Formato: String
  var parts = value.split('/');
  return new Date(parts[2], parts[1] - 1, parts[0], 0, 0, 0);
}
function formatStringDate(value) {
  // Retorno: "dd/mm/YYYY" (Visual) - Formato: String
  // Entrada: ISO Date "YYYY-mm-dd" (The International Standard) - Formato: String
  var parts = value.split('-');
  var toDate = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
  var dateFormatted =
    ('0' + toDate.getDate()).slice(-2) +
    '/' +
    ('0' + (toDate.getMonth() + 1)).slice(-2) +
    '/' +
    toDate.getFullYear();
  return dateFormatted;
}
// $('.continue-button').click(function() {
//   $('html, body').animate({ scrollTop: 0 }, 'slow');
//   return false;
// });
//----------------------
// CONTACTS
//----------------------
$('#form-contactos').on('submit', function(e) {
  e.preventDefault();
  e.returnValue = false;
});
//----------------------
// ON INPUT CHANGES
//----------------------
// Fecha de transacción
$('#ct-fecha-transaccion-field').on('change', function() {
  var value = $(this).val();
  var todayFormatted = '';
  if (isJqueryUI) {
    var todayFormatted = getTodayFormatted();
  } else {
    var todayFormatted = getTodayFormattedInverted();
  }
  if (value == todayFormatted) {
    hideNote();
    if (isJqueryUI) {
      $(this).val('Hoy');
    }
  } else {
    showNote();
  }
  removeError($(this));
});
//----------------------
// INPUTMASK - Cédula de Identidad
//----------------------
function addRemoveInputmask(value) {
  if (value) {
    $('#ct-numero-documento-field').inputmask({
      mask: '9.999.999-9',
      placeholder: '0.000.000-0',
      showMaskOnHover: false,
    });
  } else {
    $('#ct-numero-documento-field').inputmask({
      mask: '************',
      placeholder: ' ',
      showMaskOnHover: false,
    });
  }
}
function pad(input, length, padding) {
  var str = input + '';
  return length <= str.length ? str : pad(padding + str, length, padding);
}
function agregarCerosIzquierda(documento) {
  if (documento.length > 0) {
    $('#solProdDocumento').val(pad(documento, 8, '0'));
  }
}
$('#ct-numero-documento-field').focusout(function() {
  var val = $('#ct-numero-documento-field').inputmask('unmaskedvalue');
  if (val.length < 8) {
    agregarCerosIzquierda(val);
  }
});
//----------------------
// Events listeners
function eventListenerPais() {
  var selected = $(this).val();
  var putInputMask = false;
  // Pais Documento
  if (selected == 'UY') {
    putInputMask = true;
  }
  addRemoveInputmask(putInputMask);
}
function eventListenerTipo() {
  var selected = $(this).val();
  var putInputMask = false;
  // Tipo Documento
  if (selected == '1' && $('#ct-pais-documento-field').val() == 'UY') {
    putInputMask = true;
  }
  addRemoveInputmask(putInputMask);
}
//----------------------
// Destino
//----------------------
$('#ct-destino-field').on('change', function() {
  if ($(this).val().length > 0 && $(this).val() == 'alquileres') {
    $(this)
      .siblings('hr')
      .css('margin-top', '40');
    $('.input-container:nth-of-type(8)').after(advertisement);
    //Pop over
    if (!isIE) {
      $('[data-toggle="popover"]').popover({
        content: `<p>Refiere a la calidad de actuación de quien recibe los fondos, respecto del inmueble por el cual se paga el alquiler (titular o inmobiliaria).</p>`,
        container: '#datos-del-inquilino',
        trigger: 'hover focus',
        html: true,
      });
    }
    // Reset fields after Validate
    $('#ct-numero-documento-field').on('input change keyup', function() {
      $(this).css('border-color', '#cccccc');
    });
    $('#ct-numero-documento-field').focusin(function() {
      $(this).css('border-color', '#cccccc');
    });
    console.log('Datos inquilinos abrir');
    if (!isIE) {
      $('#datos-del-inquilino').show();
    } else {
      $('#datos-del-inquilino').css({ display: 'block' });
    }
    loadPaisesDropDownList('ct-pais-documento-field', window.paises, 'Uruguay');
    // Bind Inputmask event
    $('#ct-pais-documento-field')
      .bind('change', eventListenerPais)
      .trigger('change');
    $('#ct-tipo-documento-field')
      .bind('change', eventListenerTipo)
      .trigger('change');
  } else {
    $(this)
      .siblings('hr')
      .css('margin-top', '48');
    console.log('Datos inquilinos cerrar');
    if (!isIE) {
      $('#datos-del-inquilino').hide();
    } else {
      $('#datos-del-inquilino').css({ display: 'none' });
    }
    // Unbind Inputmask event
    $('#ct-pais-documento-field').unbind('change', eventListenerPais);
    $('#ct-tipo-documento-field').unbind('change', eventListenerTipo);
    advertisement.remove();
  }
});
//----------------------
// MODAL - Beneficiarios
//----------------------
$('[data-open="modal"]').on('click', function() {
  var newHeight = $('#contactModal').height();
  $('.modal-open').css('height', newHeight + 200);
});
function saveInputData() {
  var cuentaDestino = $('#ct-cuenta-destino-field');
  if (cuentaDestino.val().length > 0) {
    cuentaDestino.data('value', cuentaDestino.val());
  } else {
    cuentaDestino.data('value', '');
  }
  cuentaDestino.data('text', '');
}
function selectContact() {
  var cuentaDestino = $('#ct-cuenta-destino-field');
  var radioChecked = $('#contactModal input[type=radio]:checked');
  cuentaDestino.prop('type', 'text');
  cuentaDestino.val(radioChecked.data('text') + ' - ' + radioChecked.data('value'));
  cuentaDestino.data('value', radioChecked.data('value'));
  cuentaDestino.data('text', radioChecked.data('text'));
  cuentaDestino.prop('disabled', true);
}
function removeSelectedContact() {
  var cuentaDestino = $('#ct-cuenta-destino-field');
  $('#contactModal input[type=radio]').prop('checked', false);
  $('.btn-seleccionar').prop('disabled', true);
  cuentaDestino.prop('disabled', false);
  cuentaDestino.prop('type', 'number');
  cuentaDestino.val('');
  cuentaDestino.data('value', '');
  cuentaDestino.data('text', '');
  if ($('.success-message').is(':visible')) {
    $('.success-message').hide();
    $('#form-cuenta-terceros .input-container:nth-of-type(3)').css('margin-bottom', '24');
    $('#checkbox-save-contact').prop('checked', false);
  }
}
// Accept Modal Buttons
$('#contactModal [data-accept="modal"]').on('click', function() {
  selectContact();
  // Close Modal
  $('#contactModal').modal('toggle');
  // Reset Validation Error Style when select one contact
  var cuentaDestino = $('#ct-cuenta-destino-field');
  cuentaDestino.siblings('.validation-error-message').css('display', 'none');
  cuentaDestino.css('border-color', '#cccccc');
  //Change function from open to remove beneficiario
  $('#removeSelectedContact').show();
  $('#selectContact').hide();
  $('.save-contact').hide();
  $('#form-cuenta-terceros .input-container:nth-of-type(3)').css('margin-bottom', '24');
});
// Dismiss Modal Buttons
$('#contactModal [data-dismiss="modal"]').on('click', function() {
  if ($('#ct-cuenta-destino-field').val().length == 0) {
    $('#contactModal input[type=radio]').prop('checked', false);
    $('.btn-seleccionar').prop('disabled', true);
  }
});
$('#removeSelectedContact').on('click', function() {
  $(this).hide();
  $('#selectContact').show();
  removeSelectedContact();
});
//----------------------
// MODAL - NEW Beneficiario
//----------------------
$('#checkbox-save-contact').change(function() {
  // $('.input-container:nth-of-type(4)').css('margin-bottom', '0');
  $('#addContactModal').modal('toggle');
  var cuentaDestino = $('#ct-cuenta-destino-field');
  $('#ct-new-cuenta-destino-field').val(cuentaDestino.val());
  $('.btn-guardar').prop('disabled', true);
});
// Accept Modal Buttons
// $('#addContactModal [data-accept="modal"]').on('click', function() {
//   //SAVE Contact or Benefit  <-------------
//   // $('.message')
// });
// Dismiss Modal Buttons
$('#addContactModal [data-dismiss="modal"]').on('click', function() {
  $('#checkbox-save-contact').trigger('click');
  $('#ct-new-beneficiario-field').val('');
});
//----------------------
// ON INPUT CHANGES
//----------------------
// Cuenta destino
// When user finish writing on this field
$('#ct-cuenta-destino-field').focusout(function() {
  saveInputData();
});
// When user start to write on this field
$('#ct-cuenta-destino-field').on('input change keyup', function() {
  var cuentaDestino = $('#ct-cuenta-destino-field');
  if (cuentaDestino.val().length > 0) {
    $('.save-contact').show();
    $('#form-cuenta-terceros .input-container:nth-of-type(3)').css('margin-bottom', '0');
    $('#datos-del-inquilino .input-container:nth-of-type(3)').css('margin-bottom', '24');
  } else {
    $('.save-contact').hide();
    $('#form-cuenta-terceros .input-container:nth-of-type(3)').css('margin-bottom', '24');
  }
});
//
$('#ct-new-beneficiario-field').on('input change keyup', function() {
  if (this.value.length > 0) {
    $('.btn-guardar').prop('disabled', false);
  } else {
    $('.btn-guardar').prop('disabled', true);
  }
});
//----------------------
// RADIO BUTTONS
//----------------------
// Clean readio buttons
$('#contactModal input[type=radio]').on('click', function() {
  if ($('#contactModal input[type=radio]:checked').length > 0) {
    $('.btn-seleccionar').prop('disabled', false);
    // $('#ct-cuenta-destino-field').data('value');
  } else {
    $('.btn-seleccionar').prop('disabled', true);
  }
});
// Press entier row
$('#contactModal .tp-content button').on('click', function() {
  $(this)
    .siblings('div')
    .children('input[type="radio"]')
    .trigger('click');
});
//----------------------
// TABS
//----------------------
// Change Tab - to Cuenta Propia
function delayedResetCuentaTerceros() {
  if (!isIE) {
    ctCurrencyMask.destroy();
    $('#ct-importe-field').val('');
    ctCurrencyMask = IMask(document.getElementById('ct-importe-field'), {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 2, // digits after point, 0 for integers
          signed: false, // disallow negative
          thousandsSeparator: '.', // any single char
          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
          normalizeZeros: true, // appends or removes zeros at ends
          radix: ',', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
        },
      },
    });
  }
  resetForm($('#form-cuenta-terceros'));
  $('.save-contact').hide();
  $('#form-cuenta-terceros .input-container:nth-of-type(3)').css('margin-bottom', '24');
  $('#ct-importe-field').attr('placeholder', 'Importe');
  $('#ct-cuenta-destino-field').prop('disabled', false);
  $('#removeSelectedContact').hide();
  $('#selectContact').show();
  $('.success-message').hide();
  $('#checkbox-save-contact').prop('checked', false);
}
function resetCuentaTerceros() {
  hideNote();
  $('#datos-del-inquilino').hide();
  setTimeout(delayedResetCuentaTerceros, 500);
}
$('#nav-cuenta-propia-tab').on('click', function() {
  showCuentaPropiaButton();
  resetCuentaTerceros();
});
// Change Tab - to Cuenta terceros
function delayedResetCuentaPropia() {
  if (!isIE) {
    cpCurrencyMask.destroy();
    $('#cp-importe-field').val('');
    cpCurrencyMask = IMask(document.getElementById('cp-importe-field'), {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 2, // digits after point, 0 for integers
          signed: false, // disallow negative
          thousandsSeparator: '.', // any single char
          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
          normalizeZeros: true, // appends or removes zeros at ends
          radix: ',', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
        },
      },
    });
  }
  resetForm($('#form-cuenta-propia'));
  $('#contactModal input[type=radio]').prop('checked', false);
  $('.btn-seleccionar').prop('disabled', false);
  $('#cp-importe-field').attr('placeholder', 'Importe');
}
function resetCuentaPropia() {
  setTimeout(delayedResetCuentaPropia, 500);
}
$('#nav-cuenta-terceros-tab').on('click', function() {
  showCuentaTercerosButton();
  resetCuentaPropia();
});
// Reset fields after Validate
$('.input-field').on('input change keyup', function() {
  $(this).css('border-color', '#cccccc');
});
$('.input-field').focusin(function() {
  $(this).css('border-color', '#cccccc');
});
//----------------------
// NAVIGATION
//----------------------
function loadConfirmContent(thisForm, importe, isContact) {
  var content = document.getElementById('tpconfirmacion-content');
  content.innerHTML = '';
  //----------------------
  // Cuenta Origen
  var wrapper = document.createElement('div');
  // Cuenta Origen - Title
  var div = document.createElement('div');
  div.innerHTML = 'Cuenta de origen';
  wrapper.appendChild(div);
  // Cuenta Origen - Value
  div = document.createElement('div');
  div.innerHTML = thisForm['cuenta-origen'].value;
  wrapper.appendChild(div);
  content.appendChild(wrapper);
  //----------------------
  // Cuenta Destino
  wrapper = document.createElement('div');
  // Cuenta Destino - Title
  div = document.createElement('div');
  div.innerHTML = 'Cuenta de destino';
  wrapper.appendChild(div);
  // Cuenta Destino - Value
  div = document.createElement('div');
  div.innerHTML = thisForm['cuenta-destino'].value;
  wrapper.appendChild(div);
  content.appendChild(wrapper);
  //----------------------
  // Beneficiario
  if (thisForm.id == 'form-cuenta-terceros' && isContact) {
    wrapper = document.createElement('div');
    // Beneficiario - Title
    div = document.createElement('div');
    div.innerHTML = 'Titular de la cuenta de destino';
    wrapper.appendChild(div);
    // Beneficiario - Value
    div = document.createElement('div');
    div.innerHTML = $.grep(contactos, function(obj) {
      return obj.data.nrocuenta == thisForm['cuenta-destino'].value;
    })[0].data.beneficiario;
    wrapper.appendChild(div);
    content.appendChild(wrapper);
  }
  //----------------------
  // Destino
  if (thisForm.id == 'form-cuenta-terceros') {
    wrapper = document.createElement('div');
    // Destino - Title
    div = document.createElement('div');
    div.innerHTML = 'Destino';
    wrapper.appendChild(div);
    // Destino - Value
    div = document.createElement('div');
    div.innerHTML = $.grep(window.destino, function(obj) {
      return obj.id == thisForm['destino'].value;
    })[0].value;
    wrapper.appendChild(div);
    content.appendChild(wrapper);
  }
  //----------------------
  // Referencia
  wrapper = document.createElement('div');
  // Referencia - Title
  div = document.createElement('div');
  div.innerHTML = 'Referencia';
  wrapper.appendChild(div);
  // Referencia - Value
  div = document.createElement('div');
  if (thisForm['referencia'].value) {
    div.innerHTML = thisForm['referencia'].value;
  } else {
    div.innerHTML = 'N/A';
  }
  wrapper.appendChild(div);
  content.appendChild(wrapper);
  //----------------------
  // Fecha de transacción
  if (thisForm.id == 'form-cuenta-terceros') {
    wrapper = document.createElement('div');
    // Fecha de transacción - Title
    div = document.createElement('div');
    div.innerHTML = 'Fecha de la transferencia';
    wrapper.appendChild(div);
    // Fecha de transacción - Value
    div = document.createElement('div');
    if ($(thisForm['fecha-transaccion']).data('value') == getTodayFormatted()) {
      div.innerHTML = 'Hoy';
    } else {
      div.innerHTML = $(thisForm['fecha-transaccion']).data('value');
    }
    wrapper.appendChild(div);
    content.appendChild(wrapper);
  }
  //----------------------
  // Monto
  wrapper = document.createElement('div');
  // Referencia - Title
  div = document.createElement('div');
  div.innerHTML = 'Importe a debitar';
  wrapper.appendChild(div);
  // Referencia - Value
  div = document.createElement('div');
  div.innerHTML = importe;
  wrapper.appendChild(div);
  content.appendChild(wrapper);
  //----------------------
}
function goConfirmacion(thisForm, importe, isContact) {
  loadConfirmContent(thisForm, importe, isContact);
  formToSend = thisForm;
  // successTransference($('#tpconfirmacion-content'));
  $('#transferencias-datos').hide();
  $('#transferencias-confirmacion').show();
  $('.tp-steps .step-2').removeClass('step-gray');
  $('.tp-steps .step-2').addClass('step-orange');
  $('.tp-steps .line-1').removeClass('step-gray');
  $('.tp-steps .line-1').addClass('step-orange');
  $('#transferencias-confirmacion').css('display', 'inline-block');
  $('.tp-right-col').css('margin-top', '0px');
}
function returnDatos() {
  $('#transferencias-confirmacion').hide();
  $('#transferencias-datos').show();
  $('.tp-steps .line-1').removeClass('step-orange');
  $('.tp-steps .line-1').addClass('step-gray');
  $('.tp-steps .step-2').removeClass('step-orange');
  $('.tp-steps .step-2').addClass('step-gray');
  $('.tp-right-col').css('margin-top', '45px');
}
function goComprobante() {
  // var url = '';
  // var formObj = formToSend.serializeArray().reduce(function(currentFormObj, formEntry) {
  //   currentFormObj[formEntry.name] = formEntry.value;
  //   return currentFormObj;
  // }, {});
  // $.post(url, formObj).done(function() {
  //   successTransference($('#tpcomprobante-content'));
  // });
  window.CTARealizarTransferencia;
  successTransference($('#tpcomprobante-content'));
  $('#transferencias-confirmacion').hide();
  $('#transferencias-comprobante').show();
  $('.tp-steps .step-3').removeClass('step-gray');
  $('.tp-steps .step-3').addClass('step-orange');
  $('.tp-steps .line-2').removeClass('step-gray');
  $('.tp-steps .line-2').addClass('step-orange');
  $('#transferencias-comprobante').css('display', 'inline-block');
  if ($('#nav-cuenta-propia-tab').hasClass('active')) {
    resetCuentaPropia();
    showCuentaPropiaButton();
  } else if ($('#nav-cuenta-terceros-tab').hasClass('active')) {
    resetCuentaTerceros();
    showCuentaTercerosButton();
  }
}
function initilize() {
  $('#transferencias-comprobante').hide();
  $('#transferencias-datos').show();
  $('.tp-steps .line-1').removeClass('step-orange');
  $('.tp-steps .line-1').addClass('step-gray');
  $('.tp-steps .step-2').removeClass('step-orange');
  $('.tp-steps .step-2').addClass('step-gray');
  $('.tp-steps .line-2').removeClass('step-orange');
  $('.tp-steps .line-2').addClass('step-gray');
  $('.tp-steps .step-3').removeClass('step-orange');
  $('.tp-steps .step-3').addClass('step-gray');
  $('.tp-right-col').css('margin-top', '45px');
}
//----------------------
// SHOOTERS
//----------------------
$('#transferencias-confirmacion .return-button').on('click', function() {
  returnDatos();
});
$('#transferencias-confirmacion .continue-button').on('click', function() {
  goComprobante();
});
$('#transferencias-comprobante .return-button').on('click', function() {
  // returnConfirmacion();
  initilize();
});

// ----------------------------------------------------
// --- Submit --- ADD CONTACT
// ----------------------------------------------------
$('#add-contact-button').on('click', function(e) {
  e.preventDefault();
  var thisForm = $('#form-add-contact')[0];
  var url = '';
  window.CTAAgregarContacto(
    contactos,
    $(thisForm['ct-new-beneficiario-field']).val(),
    $(thisForm['ct-new-cuenta-destino-field']).val(),
    'Dolares',
  );
  loadContactos();
  $('.success-message').show();
  $('.save-contact').hide();
  $(
    '#contactModal input[data-value="' + $(thisForm['ct-new-cuenta-destino-field']).val() + '"]',
  ).prop('checked', true);
  selectContact();
  //Change function from open to remove beneficiario
  $('#removeSelectedContact').show();
  $('#selectContact').hide();
  $('#addContactModal').modal('toggle');
  $('#ct-new-beneficiario-field').val('');
});
// ----------------------------------------------------
// --- Submit --- CUENTA PROPIA
// ----------------------------------------------------
$('#cp-submit-button').on('click', function(e) {
  console.log('Submit form CP');
  e.preventDefault();
  var thisForm = $('#form-cuenta-propia')[0];
  //----------------------
  // Importe
  //----------------------
  var importeValue = $(thisForm['cp-importe-field']).val();
  if (!isIE) {
    $(thisForm['cp-importe-field']).prop('type', 'number');
    $(thisForm['cp-importe-field']).val(cpCurrencyMask.unmaskedValue);
  } else {
    $(thisForm['cp-importe-field']).prop('type', 'number');
    $(thisForm['cp-importe-field']).val(removeMaskIE(importeValue));
  }
  //----------------------
  if (validateFields(thisForm)) {
    goConfirmacion(thisForm, importeValue, false);
  }
  //----------------------
  // Importe
  //----------------------
  if (!isIE) {
    $(thisForm['cp-importe-field']).prop('type', 'text');
    $(thisForm['cp-importe-field']).val(cpCurrencyMask.value);
  } else {
    $(thisForm['cp-importe-field']).prop('type', 'text');
    $(thisForm['cp-importe-field']).val(importeValue);
  }
  //----------------------
  $('.validation-error-message')
    .siblings('input,select')
    .css('border-color', '#e60000');
});
// ----------------------------------------------------
// --- Submit --- CUENTA(s) TERCEROS
// ----------------------------------------------------
function hasPattern1(value) {
  // dd/mm/YYYY
  return value.match(/([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/g);
}
function hasPattern2(value) {
  // ISO Date	"YYYY-mm-dd" (The International Standard)
  return value.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g);
}
function isWeekend(value) {
  var toDate = formatDate(value);
  return isWeekendFNS(toDate);
}
function fromToday(value) {
  var today = getToday();
  var otherDate = formatDate(value);
  return otherDate >= today;
}
function setInputData() {
  var campoFecha = $('#ct-fecha-transaccion-field');
  if (campoFecha.val().length > 0) {
    if (campoFecha.val().toLowerCase() == 'hoy') {
      campoFecha.data('value', getTodayFormatted());
    } else {
      if (hasPattern2(campoFecha.val())) {
        campoFecha.data('value', formatStringDate(campoFecha.val()));
      } else {
        campoFecha.data('value', campoFecha.val());
      }
    }
  } else {
    campoFecha.data('value', '');
  }
}
$('#ct-submit-button').on('click', function(e) {
  console.log('Submit form CT');
  e.preventDefault();
  var thisForm = $('#form-cuenta-terceros')[0];
  //----------------------
  // Cuenta Destino
  //----------------------
  var isContact = false;
  var cuentaDestinoValue = $(thisForm['ct-cuenta-destino-field']).val();
  if ($('#ct-cuenta-destino-field').data('text').length > 0) {
    isContact = true;
    $(thisForm['ct-cuenta-destino-field']).prop('type', 'number');
  }
  $(thisForm['ct-cuenta-destino-field']).val($('#ct-cuenta-destino-field').data('value'));
  //----------------------
  // Fecha transacción
  //----------------------
  setInputData();
  var fechaTransaccionValue = $(thisForm['ct-fecha-transaccion-field']).val();
  if (hasPattern2(fechaTransaccionValue)) {
    $(thisForm['ct-fecha-transaccion-field']).prop('type', 'text');
  }
  $(thisForm['ct-fecha-transaccion-field']).val($('#ct-fecha-transaccion-field').data('value'));
  //----------------------
  // Importe
  //----------------------
  var importeValue = $(thisForm['ct-importe-field']).val();
  if (!isIE) {
    $(thisForm['ct-importe-field']).prop('type', 'number');
    $(thisForm['ct-importe-field']).val(ctCurrencyMask.unmaskedValue);
  } else {
    $(thisForm['ct-importe-field']).prop('type', 'number');
    $(thisForm['ct-importe-field']).val(removeMaskIE(importeValue));
  }
  //----------------------
  // General Validations
  //----------------------
  if (validateFields(thisForm)) {
    // Fecha Transacción Validations
    var realFechaTransaccionValue = $('#ct-fecha-transaccion-field').data('value');
    if (
      hasPattern1(realFechaTransaccionValue) &&
      !isWeekend(realFechaTransaccionValue) &&
      fromToday(realFechaTransaccionValue)
    ) {
      var isNumeroDocuemntoValid = true;
      if (
        $('#ct-destino-field').val().length > 0 &&
        $('#ct-destino-field').val() == 'alquileres' &&
        $('#ct-tipo-documento-field').val().length > 0 &&
        $('#ct-tipo-documento-field').val() == 1
      ) {
        if (
          $('#ct-numero-documento-field').val().length > 0 &&
          validate_ci($('#ct-numero-documento-field').val())
        ) {
          isNumeroDocuemntoValid = true;
          removeError($('#ct-numero-documento-field'));
        } else {
          isNumeroDocuemntoValid = false;
          addError($('#ct-numero-documento-field'), 'Documento no válido.');
        }
      } else {
        removeError($('#ct-numero-documento-field'));
      }
      if (isNumeroDocuemntoValid) {
        goConfirmacion(thisForm, importeValue, isContact);
        $(thisForm['ct-fecha-transaccion-field']).val(fechaTransaccionValue);
      }
    } else {
      if (!hasPattern1(realFechaTransaccionValue)) {
        $('#ct-fecha-transaccion-field').after(
          "<span class='validation-error-message'>Formato de fecha no válido</span>",
        );
      } else if (isWeekend(realFechaTransaccionValue)) {
        $('#ct-fecha-transaccion-field').after(
          "<span class='validation-error-message'>Fecha no hábil</span>",
        );
      } else if (!fromToday(realFechaTransaccionValue)) {
        $('#ct-fecha-transaccion-field').after(
          "<span class='validation-error-message'>Fecha pasada</span>",
        );
      }
      $(thisForm['ct-fecha-transaccion-field']).val(fechaTransaccionValue);
    }
  }
  //----------------------
  // Cuenta Destino
  //----------------------
  if (isContact) {
    $(thisForm['ct-cuenta-destino-field']).prop('type', 'text');
  }
  $(thisForm['ct-cuenta-destino-field']).val(cuentaDestinoValue);
  //----------------------
  // Fecha transacción
  //----------------------
  if (hasPattern2(fechaTransaccionValue)) {
    $(thisForm['ct-fecha-transaccion-field']).prop('type', 'date');
  }
  //----------------------
  // Importe
  //----------------------
  $(thisForm['ct-fecha-transaccion-field']).val(fechaTransaccionValue);
  if (!isIE) {
    $(thisForm['ct-importe-field']).prop('type', 'text');
    $(thisForm['ct-importe-field']).val(ctCurrencyMask.value);
  } else {
    $(thisForm['ct-importe-field']).prop('type', 'text');
    $(thisForm['ct-importe-field']).val(importeValue);
  }
  //----------------------
  // Errors
  //----------------------
  $('.validation-error-message')
    .siblings('input,select')
    .css('border-color', '#e60000');
});

// ----------------------------------------------------
// --- Validación Cédula de indentidad Uruguaya ---
// ----------------------------------------------------
function validate_ci(value) {
  function validation_digit(ci) {
    var a = 0;
    var i = 0;
    if (ci.length <= 6) {
      for (i = ci.length; i < 7; i++) {
        ci = '0' + ci;
      }
    }
    for (i = 0; i < 7; i++) {
      a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10;
    }
    if (a % 10 === 0) {
      return 0;
    } else {
      return 10 - (a % 10);
    }
  }

  var ci = value.replace(/\D/g, '');
  var dig = ci[ci.length - 1];
  ci = ci.replace(/[0-9]$/, '');
  if (dig == validation_digit(ci)) {
    return true;
  } else {
    return false;
  }
}
// --- Fin Validación Cédula de indentidad Uruguaya ---
//----------------------
$ = window.$;
