import validator from 'validator';

const ERROR_MESSAGE_CLASS = 'validation-error-message';
const ERROR_FIELD_CLASS = 'validation-error-field';
const VISIBLE_CLASS = 'validation-visible';

const addClass = (className, element) => {
  if (element.classList) element.classList.add(className);
  else element.className += ' ' + className;
};

const removeClass = (className, element) => {
  if (element.classList) element.classList.remove(className);
  else
    element.className = element.className.replace(
      new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
      ' ',
    );
};

const matches = (element, selector) => {
  if (!selector) return true;
  return (
    element.matches ||
    element.matchesSelector ||
    element.msMatchesSelector ||
    element.mozMatchesSelector ||
    element.webkitMatchesSelector ||
    element.oMatchesSelector
  ).call(element, selector);
};

const siblings = (element, selector) =>
  Array.prototype.filter.call(
    element.parentNode.children,
    child => child !== element && matches(child, selector),
  );

const remove = element => element.parentNode.removeChild(element);

const warnField = (field, errorText, errorSelector) => {
  const errorNodeList = siblings(field, errorSelector);
  addClass(ERROR_FIELD_CLASS, field);
  if (errorText) {
    field.insertAdjacentHTML(
      'afterend',
      `<span class="${ERROR_MESSAGE_CLASS}">${errorText}</span>`,
    );
  }
  if (errorSelector) {
    errorNodeList && errorNodeList.forEach(element => addClass(VISIBLE_CLASS, element));
  }
};

const dewarnField = (field, errorSelector) => {
  const fieldSiblings = siblings(field);
  removeClass(ERROR_FIELD_CLASS, field);

  fieldSiblings &&
    fieldSiblings.forEach(element => {
      if (matches(element, errorSelector)) removeClass(VISIBLE_CLASS, element);
      if (matches(element, `.${ERROR_MESSAGE_CLASS}`)) remove(element);
    });
};

export default element => {
  let valid = true;

  Array.prototype.forEach.call(element.querySelectorAll('[data-validate]'), field => {
    const validations = field.getAttribute('data-validate').split(',');
    const rawOptions = field.getAttribute('data-validate-options');
    const errorText = field.getAttribute('data-validate-error-text');
    const errorSelector = field.getAttribute('data-validate-error-selector');
    let validField = true;
    let options = {};

    if (rawOptions) {
      options = validator.isJSON(rawOptions) ? JSON.parse(rawOptions) : rawOptions;
    }

    dewarnField(field, errorSelector);
    field.addEventListener('focus', () => dewarnField(field, errorSelector));

    validations.forEach((validation, index) => {
      if (validField) {
        switch (validation) {
          case 'isChecked':
            if (!field.checked) {
              validField = false;
              warnField(field, errorText, errorSelector);
            } else {
              dewarnField(field, errorSelector);
            }
            break;

          case 'isFilled':
            if (!field.value || (field.options && !field.options[field.selectedIndex].value)) {
              validField = false;
              warnField(field, errorText, errorSelector);
            } else {
              dewarnField(field, errorSelector);
            }
            break;

          default:
            if (
              !validator[validation](field.value, validations.length > 1 ? options[index] : options)
            ) {
              validField = false;
              warnField(field, errorText, errorSelector);
            } else {
              dewarnField(field, errorSelector);
            }
            break;
        }
      }
    });
    valid = valid && validField;
  });
  return valid;
};
